import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/blog38.jpg'
import styles from './post-grid.module.css'

const FiberLoseWeight = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Vláknina - pomůže vám s hubnutím?'}
        description={
          'Stává se vám někdy, že se najíte a za půl hodiny máte zase hlad? Pak vám možná v jídelníčku chybí dostatek vlákniny. Právě vláknina totiž, mimo jiné, vytváří onen pocit plnosti a snižuje chuť k jídlu.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/fiber-lose-weight">
            Vláknina - pomůže vám s hubnutím?{' '}
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Vláknina - pomůže vám s hubnutím? 
"
              date="13.09.23"
            />
            <div>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />
              <p className={styles.postText}>
                Stává se vám někdy, že se najíte a za půl hodiny máte zase hlad?
                Pak vám možná v jídelníčku<b> chybí dostatek vlákniny. </b>Právě
                vláknina totiž, mimo jiné, vytváří onen pocit plnosti a snižuje
                chuť k jídlu. Na rozdíl od sacharidů, bílkovin a tuků ale
                nedodává organismu žádnou energii. V tomto článku se podíváme,
                jak moc důležitou roli v našem jídelníčku hraje právě vláknina a
                proč vám právě dostatek vlákniny<b> může pomoci s hubnutím. </b>
              </p>

              <p className={styles.postText}>
                <b>
                  <i>TIP</i>
                </b>
                
                <i>
                :
                  <a
                    href="https://nutritionpro.cz/blog/lose-weight/"
                    target="_blank"
                  >
                    {' '}Zhubněte s námi do plavek
                  </a>
                </i>
              </p>
              <p className={styles.postText}>Co je vláknina?</p>

              <p className={styles.postText}>
                Vláknina v naší potravě pochází<b> výlučně z rostlin, </b>kde
                může existovat v různých formách. Je součástí buněčných stěn,
                kde působí jako podpůrný a stavební prvek. Hlavní složkou jsou
                velice komplikované molekuly oligosacharidů a polysacharidů. Pro
                člověka je vláknina téměř zcela<b> nestravitelná, </b>jelikož
                nám v tělé schází enzymy, které by ji naštěpily a pomohly nám ji
                zpracovat. Dostává se tak až do tlustého střeva, kde už se o ni
                postarají naše<b> střevní bakterie. </b>
              </p>

              <p className={styles.postText}>Kde je nejvíce vlákniny?</p>

              <p className={styles.postText}>
                Vlákninu zpravidla dělíme na<b> rozpustnou a nerozpustnou. </b>
                Rozpustná vláknina je specifická tím, že na sebe ve střevě
                <b> váže vodu </b>(bobtná), čímž
                <b> navozuje pocit naplněnosti, </b>zvyšuje objem střevního
                obsahu, snižuje vstřebávání cholesterolu a zpomaluje vstřebávání
                glukózy z tenkého střeva (dochází tedy k pomalému zvyšování
                glykémie = hladina glukózy v krvi). Je obsažena v<b> ovoci </b>
                (například v jablkách, banánech nebo malinách) a v
                <b> luštěninách </b>(hrách, sójové boby, fazole), dále se hojně
                vyskytuje v<b> obilninách </b> (oves, žito, ječmen), zelenině
                (brokolice, mrkev) a<b> semínkách </b>(lněné a chia semínko).
                Příkladem rozpustné vlákniny je i<b> psyllium</b>, které se
                získává z jitrocele indického. Dá se užívat jen tak s vodou, ale
                hodí se i při vaření a pečení. Vlákninu tak můžete doplnit i
                touto formou.
              </p>

              <p className={styles.postText}>
                Dále máme vlákninu nerozpustnou. Oproti vláknině rozpustné
                nebobtná a zkracuje dobu, kdy zůstává potrava ve střevech, tedy
                <b> pomáhá s vyprazdňováním. </b>Můžeme si představit, že
                funguje jako savý papír a také
                <b> přitahuje škodlivé chemické látky, </b>které se dostanou do
                střev a mohou být rakovinotvorné, a odvádí je z těla pryč.
                Působí<b> preventivně i léčebně </b>na zácpu a její dostatek je
                také prevencí vzniku rakoviny tlustého střeva, která je závažným
                problémem populace České republiky. Nerozpustnou vlákninu
                najdeme v<b> ovoci a zelenině </b>(hlavně ve slupkách), v
                <b> obilninách, ořeších a semínkách.</b>
              </p>
              <p className={styles.postText}>Kolik vlákniny denně přijmout?</p>

              <p className={styles.postText}>
                Doporučená denní dávka vlákniny se pohybuje v rozmezí
                <b> 20–35 gramů. </b>Možná si nejste jisti, co si pod tímto
                číslem představit. Bohužel je fakt, že většina populace ČR
                <b> má vlákniny nedostatek. </b>Nicméně pokud budete dodržovat
                <b> zásady zdravého stravování, </b>neměli byste mít s vlákninou
                žádný problém. To znamená, že byste měli jíst 2x denně ovoce, 3x
                denně porci zeleniny, nezapomínat na ořechy a semínka, a to
                každý den, jíst celozrnné pečivo a nebát se příloh.{' '}
              </p>

              <p className={styles.postText}>
                <b>
                  <i>TIP</i>
                </b>

                <i>
                : S
                  <a href="https://nutritionpro.cz/" target="_blank">
                  {' '}našimi krabičkami{' '}
                  </a>
                  se nedostatku vlákniny nemusíte obávat!
                </i>
              </p>

              <p className={styles.postText}>
                Jak vám vláknina pomůže s hubnutím?
              </p>

              <p className={styles.postText}>
                Nejprve si řekněme, co hrozí, pokud jíte vlákniny málo. Při
                nedostatku vlákniny hrozí<b> zácpa </b>a také
                <b> nemoci zácpou způsobené, </b>jako je rakovina tlustého
                střeva, hemoroidy či křečové žíly.
              </p>
              <p className={styles.postText}>
                Jak vidíte, vláknina je velmi důležitá a to nejen při hubnutí.
                Co se hubnutí týče, pomůže vám například v tom, že vás
                <b> na dlouhou dobu zasytí </b>a vy potom, co se najíte,
                nebudete mít hned hlad, tím pádem<b> sníte i méně jídla. </b>
                Vláknina bobtná, čímž navozuje pocit naplněnosti a také
                zpomaluje vstřebání glukózy, což jsou všechno důvody, proč se
                <b> budete déle cítit sytí. </b>Nerozpustná vláknina zase
                <b> pomáhá s vyprazdňováním. </b>{' '}
              </p>

              <p className={styles.postText}>
                Vláknina má tedy několik vlastností, které vám
                <b> mohou hubnutí ulehčit </b>a především
                <b> podpořit zdravé trávení.</b>
              </p>

              <p className={styles.postText}>
                S vlákninou by se to ovšem<b> nemělo ani přehánět. </b>Zvýšený
                příjem vlákniny (nad 50 gramů denně) vede ke zvýšení nežádoucích
                účinků, jakými jsou nadýmání, plynatost, křečovité bolesti
                břicha nebo průjmy. Většina naší populace má ale spíše opačný
                problém, takže pokud do sebe nebudete vlákninu záměrně ládovat,
                nemusíte mít strach.
              </p>

              <p className={styles.postText}>
                TIP: Podívejte se na
                <a
                  href="https://nutritionpro.cz/blog/5-tipu-jak-hubnout-i-bez-diet/"
                  target="_blank"
                >
                  {' '}
                  5 tipů, jak hubnout i bez diet{' '}
                </a>
              </p>

              <p className={styles.postText}>
                Jaká je nejlepší vláknina na hubnutí?
              </p>

              <p className={styles.postText}>
                Nic takového, jako vláknina na hubnutí samozřejmě
                <b> neexistuje</b>. Vláknina má spoustu benefitů pro náš
                organismus a některé z nich váš mohou podpořit na vaší hubnoucí
                cestě. Nicméně neexistuje speciální vláknina, která by vám
                pomohla pálit kalorie, ani nic takového.
                <b> Nehledejte žádné zkratky </b>a snažte se malými krůčky
                postupně změnit váš životní styl tak, aby šla váha sama dolu. A
                když říkám sama, nemyslím snadno. Cesta za zdravým tělem i
                duchem nebývá vždy jednoduchá, ale rozhodně stojí za to. A pokud
                dáte vašemu tělu to, co potřebuje, tedy zdravou stravu, dostatek
                pohybu, tekutin a spánku,
                <b> začne se přirozeně dostávat do formy. </b>
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default FiberLoseWeight
